import ConnectGame from './connect_game/ConnectGame';
import './App.css';

function App() {
  return (
    <div className="Game-Holder">
      <ConnectGame/>
    </div>
    
  );
}

export default App;
